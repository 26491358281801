export const customerSettings = {
COMPANY_CODE: "lma",
MODE: "production",
ENV: "qa",
VERSION: 1.1,
DOMAIN: 'lma.qa.digex.be',
SCRIPTS: [],
FONTS: [],
SENTRY_DSN: "https://356d953cae7ac936b8693143d56d2cbd@o1290476.ingest.us.sentry.io/4507509201764352",
LANGUAGES: ['nl', 'fr'],
FORCE_SINGLE_LANGUAGE_IN_URL: true,
WEBSITE_NAME: "",
IMAGE_PROMO_POSITION: "",
PATH_PREFIX: "",

GDDL_ID: 'GTM-TKJQX59',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
INTERCOM_ID: '',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_TAG_MANAGER_ID: 'GTM-TKJQX59',
SEO: true,
CONSENT: false,
CONSENT_SCRIPT_URL: '//nexus.ensighten.com/dieteren/lma_29_stage/Bootstrap.js',
FAVORITES: true,
FINANCING: true,
FINANCING_SETTINGA: 'Example',
FINANCING_TEXT: true,
VEHICLE_COMPARE: true,
VEHICLE_SEGMENTATION: false
};